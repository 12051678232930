// While we don't have a i18n solution,
// we can use this file to store copy in a single place.

export const copy = {
  downloadCta: {
    title: 'Download your new favourite bank',
    qrcode: 'Scan the QR code to download the app',
  },
  aboutMonzo: {
    title: 'A bit about Monzo',
    text: 'Monzo is a fully regulated UK bank, trusted by over 11 million customers. Our mission is to make money work for everyone.',
    learnMore: 'Learn more',
  },
  appStore: {
    downloadAppStore: 'Download on the App Store',
    downloadPlayStore: 'Get it on Google Play',
  },
  header: {
    cta: {
      title: 'Join Monzo',
    },
  },
  errorBanner: {
    defaultMessage: 'Sorry, something went wrong. Please try again.',
  },
  statusHelpers: {
    paid: 'This payment has already been claimed.',
    claimed: 'This payment has already been claimed.',
    claimed_by_email:
      'Nearly there! Download Monzo and sign up to get your money.',
    claimed_by_fps:
      'Success! Your payment should arrive within 2 hours (usually instantly).',
    claimed_by_fps_loading: 'Sending...',
    failed: 'Sorry, something went wrong. Please contact the sender.',
    expired: 'This payment has expired.',
    cancelled: 'The sender has cancelled this payment.',
    unclaimed:
      "Please enter the details of your UK bank account where you'd like us to send the money.",
  },
  pages: {
    home: {
      card: {
        title: ' wants to send you ',
        cta: 'Enter bank details',
      },
    },
    claim: {
      card: {
        title: 'Enter your bank details',
        description:
          'Receive your money into any UK bank account. It can take a few hours but usually it’s instant.',
        cta: 'Get paid',
        form: {
          name: 'Name on the account',
          sortCode: 'Sort code',
          accountNumber: 'Account number',
          emailAddress: 'Email address (optional)',
          errors: {
            name: 'Please enter a valid name',
            emailAddress: 'Please enter a valid email address.',
            sortCode: 'Please enter a sort code with 6 digits',
            accountNumber: 'Please enter an account number with 8 digits',
          },
        },
        emailHelper: 'We only use this to send you confirmation of payment.',
      },
    },
    complete: {
      card: {
        success: {
          title: 'You just got paid',
          description: 'It can take a few hours but usually it’s instant. ',
          receipt: 'We’ve emailed you a receipt.',
        },
        failed: {
          title: 'Sorry, something went wrong',
          description: 'Please contact the sender. ',
          receipt: '',
        },
      },
    },
  },
}
