import React from 'react'

import { Avatar } from '@mondough/monzo-ui'

import { StatefulLayout } from '../components/'
import styles from './404.module.scss'

const NotFoundPage = ({ type = 'page' }: { type?: 'payment' | 'page' }) => {
  return (
    <StatefulLayout displayReferral={false}>
      <div className={styles.profile}>
        <Avatar name={'?'} size="extra-large" />
        <div className={styles.description}>
          {type === 'payment' ? 'Payment not found' : 'Page not found'}
        </div>
      </div>
    </StatefulLayout>
  )
}

export default NotFoundPage
