import cx from 'classnames'
import { ReactNode } from 'react'

import { useAppContext } from '../../AppContext'
import { Error } from '../../types/error'
import { Referral } from '../../types/payment'
import {
  ABitAboutMonzo,
  AppStoreButtons,
  ErrorBanner,
  Footer,
  HeaderLeft,
  HeaderRight,
  Logos,
  ReferralPrompt,
} from '../'
import styles from './Layout.module.scss'

export type LayoutElementProps = {
  children?: ReactNode
  displayDownloadLink?: boolean
  className?: string
  referral?: Referral
  displayReferral?: boolean
  simplifiedReferral?: boolean
  isReferralCtaPrimary?: boolean
  onErrorDismissal?: () => void
  error?: Error | null
}

export function Layout({ children, className }: LayoutElementProps) {
  return (
    <div className={cx(styles.container, className)} data-theme="refresh">
      <div className={styles['left-padding']}>&nbsp;</div>
      <div className={styles['right-padding']}>&nbsp;</div>
      {children}
    </div>
  )
}

export const LayoutWithComponents = ({
  children,
  error,
  onErrorDismissal,
  displayDownloadLink = true,
  referral,
  displayReferral = true,
  simplifiedReferral,
  isReferralCtaPrimary,
}: LayoutElementProps) => {
  return (
    <Layout>
      <ErrorBanner
        onBannerClick={onErrorDismissal}
        error={error}
        className={styles['error-banner']}
      />
      <HeaderLeft displayDownloadLink={displayDownloadLink} />
      <HeaderRight displayDownloadLink={displayDownloadLink} />
      <main className={styles.main}>{children}</main>
      {displayReferral && (
        <ReferralPrompt
          referral={referral}
          simplifiedCopy={simplifiedReferral}
          isCtaPrimary={isReferralCtaPrimary}
        />
      )}
      <Logos />
      <ABitAboutMonzo displayAsAside={!displayReferral} />
      {displayReferral && <AppStoreButtons />}
      <Footer />
    </Layout>
  )
}

export const StatefulLayout = (props: LayoutElementProps) => {
  const { errors, payment, setErrors } = useAppContext()

  return (
    <LayoutWithComponents
      {...props}
      error={errors}
      referral={payment?.referral}
      onErrorDismissal={() => setErrors(null)}
    />
  )
}
