import cx from 'classnames'
import * as React from 'react'

import { Title } from '@mondough/monzo-ui'
import { QRCode } from '@mondough/qr-code'

import { trackEvent } from '../../utils/analytics'
import { copy } from '../copy'
import styles from './AppStoreButtons.module.scss'

type Props = {
  prefix?: string
  qrSrc?: string
  className?: string
}
const handleClick = () => {
  trackEvent([{ name: 'payanyone-download-link.tap' }])
}

export function AppStoreButtons({ className }: Props) {
  const linkProps = {
    className: styles['download-link'],
    target: '_blank',
    rel: 'noreferrer',
    onClick: handleClick,
  }

  return (
    <div className={cx(styles['download-cta'], className)}>
      <Title is="h3" size="medium" align="center" marginBottom="medium">
        {copy.downloadCta.title}
      </Title>
      <div className={cx(styles['app-store-buttons'], className)}>
        <QRCode
          theme="light"
          size={92}
          value={
            'https://monzo.com/sign-up/?utm_source=payanyone&utm_medium=website'
          }
          ariaLabel={copy.downloadCta.qrcode}
        />
        <a
          {...linkProps}
          href="https://itunes.apple.com/gb/app/mondo/id1052238659"
        >
          <span className={styles['hidden-label']}>
            {copy.appStore.downloadAppStore}
          </span>
          <img src={'/assets/download-app-store.svg'} alt="" />
        </a>
        <a
          {...linkProps}
          href="https://play.google.com/store/apps/details?id=co.uk.getmondo&hl=en_GB&utm_source=payanyone&utm_medium=website"
        >
          <span className={styles['hidden-label']}>
            {copy.appStore.downloadPlayStore}
          </span>
          <img src={'/assets/download-google-play.svg'} alt="" />
        </a>
      </div>
    </div>
  )
}
